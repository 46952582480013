:root {
    --size: 20px;
}

.button {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

#play {
    position: relative;
    width: var(--size);
    height: var(--size);
}

#play::before,
#play::after {
    content: '';
    position: absolute;
    width: 0;
    border-style: solid;
    border-color: transparent;
    border-left-color: rgba(255, 255, 255, 0.9);
    transition: 100ms;
}

#play::before {
    left: 0;
    top: 0;
    height: calc(var(--size) / 2);
    border-width: calc(var(--size) / 4) calc(var(--size) / 2) calc(var(--size) / 4) calc(var(--size) / 2);
}

#play::after {
    left: calc(var(--size) / 2);
    top: calc(var(--size) / 4);
    height: 0;
    border-width: calc(var(--size) / 4) 0 calc(var(--size) / 4) calc(var(--size) / 2);
}

#play.playing::before {
    height: var(--size);
    border-width: 0 0 0 calc(var(--size) / 3);
}

#play.playing::after {
    left: calc(var(--size) / 1.5);
    top: 0;
    height: var(--size);
    border-width: 0 0 0 calc(var(--size) / 3);
}

.close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    width: 70px;
}

.close-button:active .cancel-icon {
    opacity: 1;
}

.cancel-icon {
    height: 18px;
    width: 18px;
    opacity: 0.7;
}

.footer-button-wrapper {
    justify-content: center;
    pointer-events: none;
    padding-top: 10px;
}

#footer-button {
    position: relative;
    background-color: rgba(0, 0, 0, 0.6);
    color: rgba(255, 255, 255, 0.8);
    padding: 10px 15px 10px 15px;
    margin-top: 15px;
    font-family: lemonMilk;
    font-size: 15px;
    transform: skew(-23deg);
    overflow: hidden;
    pointer-events: auto;
    transition: transform 500ms linear 250ms;
    animation: glow 2s infinite;
}

#footer-button-label {
    transform: skew(23deg);
}

.bye {
    transform: skew(-23deg) translateY(70px) !important;
}

span.ripple {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    background-color: rgba(255, 255, 255, 0.5);
    animation: ripple 500ms linear;
}

@keyframes ripple {
    to {
        transform: scale(4);
        opacity: 0;
    }
}

@keyframes glow {
    0% {
        box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
    }

    50% {
        box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
    }

    100% {
        box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
    }
}
